import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { hydrate, render } from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import { getCategories, getProducts } from "./redux/actions/productActions";
import rootReducer from "./redux/reducers/rootReducer";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";

import { composeWithDevTools } from "redux-devtools-extension";
import { signInWithToken } from "./redux/actions/authActions";

const store = createStore(
  rootReducer,
  load({
    states: [
      "paymentData",
      "cartData",
      "compareData",
      "currencyData",
      "multilanguage",
      "productData",
      "authInfo",
    ],
  }),
  composeWithDevTools(
    applyMiddleware(
      thunk,
      save({
        ignoreStates: ["initPointReducer"],
        states: [
          "paymentData",
          "cartData",
          "compareData",
          "currencyData",
          "multilanguage",
          "productData",
          "authInfo",
        ],
      })
    )
  )
);

// fetch products from json file

store.dispatch(getProducts());
store.dispatch(getCategories());

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Provider store={store}><App /></Provider>, rootElement);
} else {
  render(<Provider store={store}><App /></Provider>, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
