
import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  requestProducts: [],
  successfulProducts: ["payload"],
  errorProducts: ["error"],
  requestCategories: [],
  successfulCategories: ["payload"],
  errorCategories: ["error"],
});

export const ProductsActionCreators = Creators;

const initialState = {
  loading: false,
  products: [],
  error: "",
  selectedProduct: {},
  searchText: "",
  message: "",
  categories: [],
};

const requestProducts = (state = initialState) => ({
  ...state,
  loading: true,
  error: "",
  products: [],
});

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const successfulProducts = (state = initialState, action) => {
  const { message, products } = action.payload.result;
  return {
    ...state,
    loading: false,
    message,
    products: shuffleArray(products)
  };
};

const errorProducts = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
  products: null,
});

const requestCategories = (state = initialState) => ({
  ...state,
  loading: true,
  error: "",
  categories: [],
});

const successfulCategories = (state = initialState, action) => {
  const { message, categories } = action.payload.result;
  return {
    ...state,
    loading: false,
    message,
    categories: categories.reverse(),
  };
};


const errorCategories = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
  categories: null,
});

export default createReducer(initialState, {
  [Types.REQUEST_PRODUCTS]: requestProducts,
  [Types.SUCCESSFUL_PRODUCTS]: successfulProducts,
  [Types.ERROR_PRODUCTS]: errorProducts,
  [Types.REQUEST_CATEGORIES]: requestCategories,
  [Types.SUCCESSFUL_CATEGORIES]: successfulCategories,
  [Types.ERROR_CATEGORIES]: errorCategories,
});